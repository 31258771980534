.login-container2[data-v-a49090ce] {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #2d3a4b;
}
.login-container2 .login-form[data-v-a49090ce] {
  width: 130%;
  margin: 0 auto;
  margin-top: 56px;
}
.login-container2 .tips[data-v-a49090ce] {
  font-size: 14px;
  color: #666;
}
.login-container2 .tips span[data-v-a49090ce]:first-of-type {
  margin-right: 16px;
}
.login-container2 .svg-container[data-v-a49090ce] {
  padding: 0 5px 0 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 30px;
  font-size: 17px;
}
.login-container2 .title-container[data-v-a49090ce] {
  position: relative;
}
.login-container2 .title-container .title[data-v-a49090ce] {
  font-size: 20px;
  margin: 0px auto 40px auto;
  text-align: left;
}
.login-container2 .title-container .title2[data-v-a49090ce] {
  font-size: 26px;
  color: var(--themeColor, #17a2b8);
  margin: 0px auto 40px auto;
  text-align: left;
  font-weight: bold;
}
.login-container2 .title-container .set-language[data-v-a49090ce] {
  color: #fff;
  position: absolute;
  top: 5px;
  right: 0px;
}
.login-container2 .show-pwd[data-v-a49090ce] {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.login-container2 .thirdparty-button[data-v-a49090ce] {
  position: absolute;
  right: 35px;
  bottom: 28px;
}
.login-container2 .inputDeep[data-v-a49090ce] .el-input__wrapper {
  box-shadow: none;
  cursor: default;
  background-color: #eeeeee;
}
.login-container2 .inputDeep[data-v-a49090ce] .el-input__wrapper .el-input__inner {
  cursor: default !important;
}