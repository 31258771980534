/* *{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
} */
/* body{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: #eff0f4;
} */
.container{
	position: relative;
	left: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.container .drop{
	position: relative;
	width: 350px;
	height: 350px;
	box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),25px 35px 20px rgba(0,0,0,0.05),25px 30px 30px rgba(0,0,0,0.05),
		inset -20px -20px 25px rgba(255,255,255,0.9);
	transition: 0.5s;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 62% 38% 24% 76% / 59% 60% 40% 41%  ;
}
.container .drop:hover{
	border-radius: 50%;
}
.container .drop::before{
	content:'';
	position: absolute;
	top: 50px;
	left: 85px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #fff;
	opacity: 0.9;
}
.container .drop::after{
	content: '';
	position: absolute;
	top: 85px;
	left: 110px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #fff;
	opacity: 0.9;
}
.container .drop .content{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	padding: 40px;
	gap: 15px;
}
.container .drop .content h2{
	position: relative;
	color: #333;
	font-size: 1.5em;
}
.container .drop .content form{
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;
}
.container .drop .content form .input-box{
	position: relative;
	width: 225px;
	box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
		inset -2px -5px 10px rgba(255,255,255,1),15px 15px 10px rgba(0,0,0,0.05),15px 10px 15px rgba(0,0,0,0.025);
	border-radius: 25px;
}
.container .drop .content form .input-box::before{
	content: '';
	position: absolute;
	top: 4px;
	left: 50%;
	transform: translateX(-50%);
	width: 65%;
	height: 2px;
	background: rgba(255,255,255,0.5);
	border-radius: 5px;
}
.container .drop .content form .input-box input{
	border: none;
	outline: none;
	background: transparent;
	width: 100%;
	font-size: 1em;
	padding: 10px 15px;
}
.container .drop .content form .input-box input[type='submit']{
	color: #fff;
	text-transform: uppercase;
	font-size: 0.8em;
	cursor: pointer;
	letter-spacing: 0.4em;
	font-weight: 500;
	line-height: 0.9em;
}
.container .drop .content form .input-box:last-child{
	width: 120px;
	background: #3a86ff;
	box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
15px 15px 10px rgba(0,0,0,0.05),15px 10px 15px rgba(0,0,0,0.025);
	transition: 0.5s;
}
.container .drop .content form .input-box:last-child:hover{
	width: 150px;
}
.btn{
	position: absolute;
	right: -120px;
	bottom: 0;
	width: 120px;
	height: 120px;
	background: #17a2b8;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: #fff;
	line-height: 1.2em;
	letter-spacing: 0.1em;
	font-size: 0.8em;
	transition: 0.25s;
	text-align: center;
	box-shadow: inset 10px 10px 10px rgba(0,180,216,0.05),15px 25px 10px rgba(0,180,216,0.1),15px 20px 20px rgba(0,180,216,0.1),
		inset -10px -10px 15px rgba(255,255,255,0.5); 
	border-radius: 44% 56% 65% 35% / 57% 58% 42% 43% ;
}
.btn::before{
	content: '';
	position: absolute;
	top: 15px;
	left: 30px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	opacity: 0.45;
}
.btn.signup{
	bottom: 150px;
	right: -140px;
	width: 80px;
	height: 80px;
	border-radius: 49% 51% 52% 48% / 63% 59% 41% 37%;
	background: #f77f00;
	box-shadow: inset 10px 10px 10px rgba(247,127,0,0.05),15px 25px 10px rgba(247,127,0,0.1),15px 20px 20px rgba(247,127,0,0.1),
		inset -10px -10px 15px rgba(255,255,255,0.5); 
	
}
.btn.signup::before{
	left: 20px;
	width: 15px;
	height: 15px;
}
.btn:hover{
	border-radius: 50%;
}

/* 气泡字体 */
.container2 .drop2{
	position: relative;
	width: 100px;
	height: 100px;
	box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),25px 35px 20px rgba(0,0,0,0.05),25px 30px 30px rgba(0,0,0,0.05),
		inset -20px -20px 25px rgba(255,255,255,0.9);
	transition: 0.5s;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 62% 38% 24% 76% / 59% 60% 40% 41%  ;
}
.container2 .drop2:hover{
	border-radius: 50%;
}