@charset "UTF-8";
@supports (-webkit-mask: none) and (not (cater-color: #999)) {
.login-container .el-input input {
    color: #999;
}
.login-container .el-input input::first-line {
    color: #666;
}
}
.videoStyle {
  position: absolute;
  top: 50%;
  left: 46.6%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
}
.el-container.is-vertical {
  flex-direction: column;
  height: 100%;
}
.appLogin {
  background-image: url(../img/loginbg.ef31d5e7.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
  width: 100%;
  height: 100vh;
  /* 使容器高度等于视口高度 */
  font-family: "Microsoft";
}
.appLogin .login_left {
  width: 100%;
  height: 100%;
  padding: 2.7083333333vw 0 0 7.65625vw;
}
.appLogin .login_left .left_icon {
  position: relative;
  top: 8%;
  left: 12%;
  width: 78%;
  height: 70%;
}
.appLogin p {
  font-family: "Microsoft", sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  line-height: 1.25vw;
  color: rgb(51, 51, 51);
}
.appLogin .left_title {
  height: 3.9583333333vw;
  display: flex;
  align-items: center;
}
.appLogin .left_title .text {
  font-size: 1.875vw;
  font-weight: bold;
  letter-spacing: 1px;
}
.appLogin .left_title .icon {
  width: 3.4375vw;
  height: 1.71875vw;
  background-color: #3772EF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px 20px 20px 0px;
  margin-left: 0.3645833333vw;
  font-size: 1.0416666667vw;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.40625vw;
  color: rgb(255, 255, 255);
}
.appLogin .top_logo {
  padding: 1.4583333333vw;
}
.appLogin .left_bottom {
  width: 90%;
  margin: 0 auto;
  height: 3.9583333333vw;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.625em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: 0.5208333333vw;
  position: relative;
  line-height: 3.9583333333vw;
  margin-left: 4.1666666667vw;
  padding: 0.5208333333vw 0.78125vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.appLogin .left_bottom .bottom_text {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 0.8333333333vw;
  font-weight: 400;
  letter-spacing: 0.0520833333vw;
  line-height: 1.09375vw;
  color: rgba(56, 56, 56, 0.9);
}
.appLogin .login_right {
  width: 26.0416666667vw;
  height: 31.5104166667vw;
  background-color: #ffffff;
  margin: 3.8020833333vw 0 0 2.2395833333vw;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 4.375vw 1.6666666667vw;
}
.appLogin .right_title {
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.875vw;
  color: rgb(51, 51, 51);
  text-align: center;
  letter-spacing: 1.5px;
  height: 1.875vw;
}
.appLogin .right_login {
  height: 11.25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.appLogin .login_right .el-input__wrapper {
  border-radius: 0 !important;
  height: 2.7604166667vw;
  border-radius: 0.5208333333vw !important;
  background-color: #EFF1F4;
}
.appLogin .login_right .el-input {
  --el-input-border-color: #EFF1F4 !important;
}
.appLogin .login_right .tips {
  margin-bottom: 1.8229166667vw;
}
.appLogin .login_right .right_btn {
  text-align: center;
  padding: 1.6666666667vw 0;
  cursor: pointer;
}
.appLogin .login_right .el-button.is-round {
  padding: 1.3020833333vw 0;
}
.appLogin .login_right .el-button--primary {
  background: #478CF9 !important;
  color: #fff;
  border-color: #478CF9 !important;
}
.appLogin .login_right .el-button {
  width: 100%;
  font-size: 0.8333333333vw !important;
  letter-spacing: 0.1041666667vw;
  border-radius: 0.5208333333vw !important;
}
.appLogin .login_right .inputDeep {
  border-radius: 0.5208333333vw !important;
}
.bq {
  font-size: 0.7291666667vw;
  text-align: center;
}
@media only screen and (max-width: 768px) {
.appLogin .el-row {
    flex-direction: column;
}
.appLogin .top_logo {
    padding: 1.5%;
}
.appLogin .login_left {
    display: none;
}
.appLogin .login-right-box {
    width: 320px !important;
    max-width: unset;
    margin: 0 auto;
    padding: 0 !important;
}
.appLogin .login_right {
    width: 320px;
    height: 500px;
    border-radius: 8px;
    padding: 54px 32px;
    margin: 0;
    margin-top: 3.8020833333vw;
}
.appLogin .right_title {
    font-size: 24px;
    line-height: 36px;
    height: 36px;
}
.appLogin .right_login {
    height: 216px;
}
.appLogin .login_right .el-input__wrapper {
    height: 53px;
    border-radius: 10px;
}
.appLogin .login_right .tips {
    margin-bottom: 35px;
}
.appLogin .login_right .el-button.is-round {
    padding: 20px 0;
}
.appLogin .login_right .right_btn {
    padding: 32px 0;
}
.appLogin .login_right .el-button {
    font-size: 16px !important;
    letter-spacing: 2px !important;
    border-radius: 10px !important;
}
.appLogin .login_right .inputDeep {
    border-radius: 10px;
}
.appLogin .bq {
    font-size: 13px;
}
}
.login-container2 {
  width: 100%;
  height: 100%;
}
.login-container2 .background_box {
  position: relative;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  background-size: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding-bottom: 50px;
}
.login-container2 .background_box .leftImg {
  float: left;
  height: 100%;
  width: 45%;
  border-right: 1px solid lightgray;
}
.login-container2 .background_box .right_form {
  background-color: #fff;
  position: absolute;
  border-radius: 3px;
  width: 800px;
  z-index: 20;
  height: 520px;
  max-width: 100%;
  margin: auto;
  top: 0;
  bottom: 100px;
  left: 0;
  right: 5px;
  overflow: hidden;
  box-shadow: 0 2px 10px #ddd;
}
.login-container2 .background_box .right_form .login_btn {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3px !important;
  line-height: 32px !important;
  height: 44px !important;
  font-size: 21px;
}
.login-container2 .el-input {
  display: inline-block;
  height: 36px;
  width: 82%;
}
.login-container2 .el-input input {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 0 10px;
  color: #333;
  height: 33px;
  line-height: 36px;
  padding: 0 !important;
  caret-color: #999;
}
.login-container2 .el-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #eee inset !important;
  -webkit-text-fill-color: #999 !important;
}
.login-container2 .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #eee;
  border-radius: 3px;
  color: #454545;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px !important;
}